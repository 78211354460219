import { motion } from "framer-motion"
import styled from "styled-components"

export const Flex = styled(motion.div)`
  display: flex;

  ${({ wrap }) => wrap && `flex-wrap: ${wrap};`}
  ${({ direction }) => direction && `flex-direction: ${direction};`}
  ${({ align }) => align && `align-items: ${align};`}
  ${({ justify }) => justify && `justify-content: ${justify};`}
  ${({ gap }) => gap && `gap: ${gap}px;`}
`
export const Wrapper = styled.div`
  width: 100%;
`
export const ContentWrapper = styled.div`
  max-width: 1234px;
  margin: 0 auto;
  /* padding: 100px 20px; */
`
export const TextWrapper = styled.div`
  max-width: 55ch;
`

export const ErrorMsg = styled.span`
  color: #D6371A;
  font-size: 16px;
  font-weight: 500;
  a { color: #D6371A; }
`;

export const Block = styled(motion.div)`
  display: block;
`